<template lang="">
  <div>
    <SubHeader :data="data" />
    <div class="container">
      <div class="container-text">
        <h1 style="text-align: center; color: #3370FF; font-size: 34px; margin-bottom: 20px;">{{ $t(`FAQ['howMayHelp']`) }}</h1>
        <p style="margin-bottom: 20px">{{ $t(`FAQ['goodToKnow']`) }}</p>
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item v-for="(item, index) in faq" :key="index" :title="$t(`FAQ['${item.title}']`)">
            <div>{{ $t(`FAQ['${item.content}']`) }}</div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import SubHeader from '../components/SubHeader.vue'
export default {
  name: 'FAQ',
  components: {
    SubHeader
  },
  data() {
    return {
      data: {
        label_name: 'frequentlyAsk',
        content_desc: 'faqContent',
        img: require('@/assets/image/sub-header/frequancy.png')
      },
      faq: [
        { title: 'title1', content: 'desc1' },
        { title: 'title2', content: 'desc2' },
        { title: 'title3', content: 'desc3' },
        { title: 'title4', content: 'desc4' },
        { title: 'title5', content: 'desc5' },
        { title: 'title6', content: 'desc6' },
        { title: 'title7', content: 'desc7' },
        { title: 'title8', content: 'desc8' },
        { title: 'title9', content: 'desc9' },
        { title: 'title10', content: 'desc10' },
        { title: 'title11', content: 'desc11' },
        { title: 'title12', content: 'desc12' },
        { title: 'title13', content: 'desc13' },
        { title: 'title14', content: 'desc14' },
        { title: 'title15', content: 'desc15' },
        { title: 'title16', content: 'desc16' },
        { title: 'title17', content: 'desc17' },
        { title: 'title18', content: 'desc18' },
        { title: 'title19', content: 'desc19' },
        { title: 'title20', content: 'desc20' },
        { title: 'title21', content: 'desc21' },
        { title: 'title22', content: 'desc22' },
        { title: 'title23', content: 'desc23' },
        { title: 'title24', content: 'desc24' },
        { title: 'title25', content: 'desc25' },
        { title: 'title26', content: 'desc26' }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.container-text {
  font-size: 20px;
  font-weight: 500;
  padding: 40px 0 70px;
  ::v-deep
  .el-collapse {
    border: none;
    .el-collapse-item__header {
      cursor: pointer;
      border: 1px solid #EBEEF5;
      border-radius: 15px;
      margin-bottom: 10px;
      padding: 10px 15px 10px 15px;
      font-size: 20px;
    }
    .el-collapse-item__header.is-active {
        border-bottom-color: transparent !important;
        border-radius: 15px 15px 0 0 !important;
        margin-bottom: 0 !important;
    }
    .el-collapse-item__wrap {
      border: 1px solid #EBEEF5;
      border-radius: 0 0 15px 15px;
      padding: 0 15px 0 15px;
      border-top: 0;
      margin-bottom: 15px;
      .el-collapse-item__content {
        font-size: 17px;
        font-weight: 400;
        color: #595959;
;

      }
    }
  }
}
</style>
